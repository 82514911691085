import type { AppSubjects } from "@lbcrm-api/casl/app.subjects";
import {
    Icon,
    IconHexagons,
    IconUsers,
    IconShieldCheck,
    IconCalendarCog,
    IconUserCog,
    IconHome2,
    IconTag,
    IconAddressBook,
    IconProgressCheck,
    IconBus,
    IconHexagon,
    IconSettings,
    IconHexagonOff,
    IconHexagonsOff,
    IconMusic,
    IconFileMusic,
} from "@tabler/icons-react";
import { Can } from "src/authentication/context/Can";
import { Action } from "src/casl/Action";
import { useMinistryInvolvements } from "src/ministries/dashboard/useMinistryInvolvements";
import { SidebarAppsMenuNavLink } from "./components/SidebarAppsMenuNavLink";
import { SidebarAppsMenuMenu } from "./components/SidebarAppsMenuMenu";
import { SidebarAppsMenuHeading } from "./components/SidebarAppsMenuHeading";
import dayjs from "dayjs";

interface AppLink {
    to: string;
    label: string;
    icon: Icon;
    subject: AppSubjects;
}

export const SidebarAppsMenu = () => {
    const { ministryInvolvements } = useMinistryInvolvements();

    const now = dayjs();

    const activeMinistries = ministryInvolvements?.filter((ministry) =>
        ministry.seasons.some((season) => !season.endDate || dayjs(season.endDate) > now)
    );

    const inActiveMinistries = ministryInvolvements?.filter((ministry) =>
        ministry.seasons.every((season) => season.endDate && dayjs(season.endDate) <= now)
    );

    return (
        <>
            <Can I={Action.Read} a="Ministry">
                <SidebarAppsMenuHeading>
                    <IconHexagons /> Ministries
                </SidebarAppsMenuHeading>

                <SidebarAppsMenuMenu>
                    {activeMinistries &&
                        activeMinistries.map((ministryInvolvement) => (
                            <li key={ministryInvolvement.id}>
                                <SidebarAppsMenuNavLink to={`/ministries/${ministryInvolvement.id}`}>
                                    <IconHexagon />
                                    {ministryInvolvement.name}
                                </SidebarAppsMenuNavLink>
                            </li>
                        ))}

                    {inActiveMinistries && inActiveMinistries.length > 0 && (
                        <li>
                            <details>
                                <summary className="cursor-pointer">
                                    <IconHexagonsOff />
                                    Inactive ministries
                                </summary>

                                <ul>
                                    {inActiveMinistries.map((ministryInvolvement) => (
                                        <li key={ministryInvolvement.id}>
                                            <SidebarAppsMenuNavLink to={`/ministries/${ministryInvolvement.id}`}>
                                                <IconHexagonOff />
                                                {ministryInvolvement.name}
                                            </SidebarAppsMenuNavLink>
                                        </li>
                                    ))}
                                </ul>
                            </details>
                        </li>
                    )}
                </SidebarAppsMenuMenu>
            </Can>

            <Can I={Action.Manage} a="Person">
                <SidebarAppsMenuHeading>
                    <IconUsers /> People
                </SidebarAppsMenuHeading>

                <SidebarAppsMenuMenu>
                    {[
                        {
                            to: "directory",
                            label: "Directory",
                            icon: IconAddressBook,
                            subject: "Person",
                        } satisfies AppLink,
                        {
                            to: "households",
                            label: "Households",
                            icon: IconHome2,
                            subject: "Household",
                        } satisfies AppLink,
                        { to: "tags", label: "Tags", icon: IconTag, subject: "Tag" } satisfies AppLink,
                    ].map((link) => (
                        <Can key={link.to} I={Action.Read} a={link.subject}>
                            <li key={link.to}>
                                <SidebarAppsMenuNavLink className="rounded-lg" to={"/people/" + link.to}>
                                    <link.icon /> {link.label}
                                </SidebarAppsMenuNavLink>
                            </li>
                        </Can>
                    ))}
                </SidebarAppsMenuMenu>
            </Can>

            <Can I={Action.Manage} a="MinistryAttendanceConsent">
                <SidebarAppsMenuHeading>
                    <IconShieldCheck /> Child protection
                </SidebarAppsMenuHeading>

                <SidebarAppsMenuMenu>
                    {[
                        {
                            to: "forms/parental-consent-forms",
                            label: (
                                <span>
                                    <abbr title="Parental consent form">PCF</abbr> and medical
                                </span>
                            ),
                            icon: IconProgressCheck,
                        },
                    ].map((link) => (
                        <li key={link.to}>
                            <SidebarAppsMenuNavLink end className="rounded-lg" to={"/child-protection/" + link.to}>
                                <link.icon /> {link.label}
                            </SidebarAppsMenuNavLink>
                        </li>
                    ))}
                </SidebarAppsMenuMenu>
            </Can>

            <Can I={Action.Manage} a="SongLibrarySong">
                <SidebarAppsMenuHeading>
                    <IconMusic /> Songs
                </SidebarAppsMenuHeading>

                <SidebarAppsMenuMenu>
                    <li>
                        <SidebarAppsMenuNavLink to="song-libraries">
                            <IconFileMusic /> Song libraries
                        </SidebarAppsMenuNavLink>
                    </li>
                </SidebarAppsMenuMenu>
            </Can>

            <Can I={Action.Manage} an="all">
                <SidebarAppsMenuHeading>
                    <IconSettings /> Settings
                </SidebarAppsMenuHeading>

                <SidebarAppsMenuMenu>
                    {[
                        { to: "users", label: "Users", icon: IconUserCog },
                        { to: "ministries", label: "Ministries admin", icon: IconHexagons },
                        { to: "calendar-events", label: "Calendar event resources", icon: IconCalendarCog },
                        { to: "vehicles", label: "Vehicles", icon: IconBus },
                    ].map((link) => (
                        <li key={link.to}>
                            <SidebarAppsMenuNavLink end className="rounded-lg" to={`/settings/${link.to}`}>
                                <link.icon /> {link.label}
                            </SidebarAppsMenuNavLink>
                        </li>
                    ))}
                </SidebarAppsMenuMenu>
            </Can>
        </>
    );
};
